import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import capitalize from '@material-ui/core/utils/capitalize';
import { styles } from './Container.style';

const Container = React.forwardRef(function Container(props, ref) {
  const {
    classes,
    className = '',
    component: Component = 'div',
    fixed = false,
    maxWidth = 'xl',
    useContainer = true,
    overflowHidden = false,
    ...other
  } = props;

  const container = (
    <Component
      className={clsx(
        classes.root,
        {
          [classes.fixed]: fixed,
          [classes[`maxWidth${capitalize(String(maxWidth))}`]]:
            maxWidth !== false,
        },
        className
      )}
      ref={ref}
      {...other}
    />
  );

  if (!useContainer) {
    return props.children;
  }

  return (
    <section
      className={clsx(classes.root, {
        [classes.overflowHidden]: overflowHidden,
      })}
    >
      {container}
    </section>
  );
});

Container.propTypes = {
  children: PropTypes.node.isRequired,
  /**
   * Override or extend the styles applied to the component.
   * See [CSS API](#css) below for more details.
   */
  classes: PropTypes.object.isRequired,
  /**
   * @ignore
   */
  className: PropTypes.string,
  /**
   * The component used for the root node.
   * Either a string to use a DOM element or a component.
   */
  component: PropTypes.elementType,
  /**
   * Set the max-width to match the min-width of the current breakpoint.
   * This is useful if you'd prefer to design for a fixed set of sizes
   * instead of trying to accommodate a fully fluid viewport.
   * It's fluid by default.
   */
  fixed: PropTypes.bool,
  /**
   * Determine the max-width of the container.
   * The container width grows with the size of the screen.
   * Set to `false` to disable `maxWidth`.
   */
  maxWidth: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl', false]),

  useContainer: PropTypes.bool,
};

export default withStyles(styles, { name: 'MuiContainer' })(Container);
