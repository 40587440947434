import { makeStyles } from '@material-ui/core/styles';
import { borderRadius } from '../../../../styles/themes/vad/borderRadius';
import { pxToRem } from '../../../../styles/themes/vad/constants';
import { fontSize } from '../../../../styles/themes/vad/font';

const useStyles = makeStyles(theme => ({
  notificationWrapper: {
    position: 'relative',
    zIndex: 3,
  },
  root: {
    width: '100%',
    '& .mobileNotification': {
      [theme.breakpoints.up('lg')]: {
        display: 'none',
      },
    },
    '& .desktopNotification': {
      [theme.breakpoints.down('md')]: {
        display: 'none',
      },
    },
    '& .MuiAlert-root': {
      color: theme.mixins.Black(),
      borderRadius: borderRadius.b0,
      backgroundColor: theme.palette.background.yellow10,
      [theme.breakpoints.up('lg')]: {
        padding: `15px ${pxToRem(92)}`,
        minHeight: 0,
      },

      '& > .MuiAlert-icon': {
        color: theme.mixins.Black(),
        [theme.breakpoints.up('lg')]: {
          padding: `${pxToRem(10)} 0`,
        },
      },
      '& .MuiAlert-action': {
        [theme.breakpoints.down('md')]: {
          alignItems: 'baseline',
        },
        '& button:hover svg': {
          color: theme.palette.primary.contrastText,
        },
      },
    },
    '& .infoIconTop': {
      '& .MuiAlert-root': {
        [theme.breakpoints.down('md')]: {
          alignItems: 'start',
          paddingTop: `${pxToRem(18)}`,
          paddingBottom: `${pxToRem(13)}`,
          ...theme.mixins.paddingRight(pxToRem(18)),
          ...theme.mixins.paddingLeft(pxToRem(22)),
          '& > .MuiAlert-icon': {
            marginTop: '4px',
          },
        },
        '& .ctaBtn': {
          marginTop: pxToRem(4),
          textDecoration: 'none',
        },

      },
    },
  },
  messageWrap: {
    ...theme.mixins.marginRight(pxToRem(12)),
  },
  message: {
    [theme.breakpoints.down('md')]: {
      fontFamily: theme.custom.fontFamily.primaryBold,
    },
  },
  favNotificationText: {
    [theme.breakpoints.down('md')]: {
      fontFamily: theme.custom.fontFamily.primaryRegular,
      fontSize: fontSize.s16Rem,
      lineHeight: fontSize.s24Rem,
    },
  },
  favMessageWrap: {
    ...theme.mixins.marginRight(pxToRem(26)),
  },
}));

export default useStyles;
